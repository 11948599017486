<template>
    <div class="find_competitive">
        <template v-if="$store.state.userInfo.vip_type != 5">
            <div class="search_company" id="top">
                <div class="header_title">智能匹配竞品</div>
                <div class="company">
                    <span class="label">当前公司：</span>
                    <div class="click right" @click="changeCompany">
                        <img v-if="searchCompany.default" src="@/assets/img/search/icon_lock.png" alt="" width="16" height="18">
                        <span  class="text">{{searchCompany.company_name || '加载中...'}}</span>
                        <span style="color: #5ea4fa; margin-right: 8px">切换</span>
                        <img src="@/assets/img/search/icon_change.png" alt="" width="18" height="14">
                    </div>
                </div>
                <div class="area">
                    <span class="s-l-label">所在地区：</span>
                    <div class="s-l-box">
                        <div class="cascader">
                            <el-popover
                                trigger="hover"
                                popper-class="cascader-popper"
                                placement="bottom-start"
                                v-model="areaVisible">
                                <el-cascader-panel
                                    :options="areaOptions"
                                    :props="props"
                                    v-model="search.s_area"
                                    @change="areaSelect">
                                </el-cascader-panel>
                                <span
                                    slot="reference"
                                    :class="{ 'cascader-span': area_name != '全部地区' }">
                            {{ area_name }}
                             <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                            </el-popover>
                        </div>
                    </div>
                </div>
                <transition name="el-fade-in-linear">
                    <div class="select-list" v-if="area_name_str">
                        <span class="select-list-label">已选条件：</span>
                        <div class="select-list-box">
                        <span v-if="area_name_str">所在地区：
                            {{
                                area_name_str.length > 20
                                ? area_name_str.substring(0, 20) + "..."
                                : area_name_str
                            }}
                            <font @click="clearArea"></font>
                        </span>
                        </div>
                        <div class="select-list-clear" @click="clearArea">清除条件</div>
                    </div>
                </transition>
            </div>
            <div class="list_box">
                <div class="head">
                    <span class="result">共找到<span class="count">{{listData.real_cnt > 100000? '100000+':listData.real_cnt}}</span>个推广竞品</span>
                    <myButton v-if="$store.state.userInfo.has_export_permission" type="default" width="158" height="33" @click="exportTel" class="button"  style="right: 130px;">
                        <img slot="img" src="@/assets/img/companyDetails/icon_export.png" alt="" width="14px" height="14px">
                        <span slot="text">导出企业联系电话</span>
                    </myButton>
                </div>
                <el-table
                    :data="listData.rows"
                    v-loading="tableLoading"
                    @sort-change="listSortChange"
                    style="width: 100%">
                    <el-table-column
                        min-width="450"
                        label="公司信息">
                        <template slot-scope="scope">
                            <div class="list_item">
                                <div class="list-left">
                                    <div class="list-left-txt" :style="{ background: scope.row.logo_color}">
                                        <font>{{ scope.row.logo_img_str }}</font>
                                    </div>
                                </div>
                                <div class="list_right">
                                    <div class="company_line">
                                        <div class="company_name" v-html="scope.row.compete_company_name" @click="$C.getCompanyDetails(scope.row.company_name_digest)"></div>
                                        <div :class="scope.row.company_status=='在业' || '续存'?'running':'close_down'">{{scope.row.company_status}}</div>
                                    </div>
                                    <div class="extend">
                                    <span class="extend_item">
                                        <img src="@/assets/img/search/icon_qd.png" alt="">
                                        <span class="title">推广平台：</span>
                                        <span class="container">{{scope.row.nAdPlatform.length + '个' || '--'}}</span>
                                    </span>
                                        <span class="extend_item">
                                        <img src="@/assets/img/search/icon_count.png" alt="">
                                        <span class="title">推广关键词：</span>
                                        <span class="container">{{scope.row.nAdWordCnt}}个</span>
                                    </span>
                                        <span class="extend_item">
                                        <img src="@/assets/img/search/history.png" alt="">
                                        <span class="title">推广记录：</span>
                                        <span class="container">{{scope.row.nAdRecordCnt}}条</span>
                                    </span>
                                        <span class="extend_item">
                                        <img src="@/assets/img/search/icon_address.png" alt="">
                                        <span class="title">所在地区：</span>
                                        <span class="container">{{scope.row.city}}</span>
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        min-width="130"
                        sortable="custom"
                        label="相同关键词数">
                        <template slot-scope="scope">
                            <span>{{scope.row.compete_word_cnt}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        min-width="180"
                        label="相同关键词">
                        <template slot-scope="scope">
                        <span v-for="(item, i) in scope.row.compete_word.split(' ').slice(0,3)" :key="i">
                            <span
                                @click="toCompare(scope, item)"
                                class="new_link">{{item}}</span
                            ><span v-if="i < scope.row.compete_word.split(' ').length-4">、</span>
                        </span>等
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="lasttime"
                        min-width="110"
                        label="最后推广时间">
                    </el-table-column>
                    <el-table-column
                        min-width="100"
                        label="操作">
                        <template slot-scope="scope">
                            <my-button @click="toCompare(scope)" type="primary" width="90" height="33" style="margin-bottom: 10px">
                                <span slot="text">去对比</span>
                            </my-button><br>
                            <my-button v-if="!scope.row.monitor" @click="handelMonitor(scope.row.company_name_digest, 1)" type="default" width="90" height="33">
                                <span slot="text">监测推广</span>
                            </my-button>
                            <el-dropdown v-else>
                                <my-button type="secondary" width="90" height="33" style="background-color: #b5c2d2">
                                    <img slot="img" src="@/assets/img/companyDetails/supervise.png" alt="" width="14px" height="14px">
                                    <span slot="text" style="color: #FFFFFF">监测中</span>
                                </my-button>
                                <el-dropdown-menu slot="dropdown" >
                                    <el-dropdown-item>
                                        <div class="line" @click="$router.push('/box/dynamic')">查看监测动态</div>
                                    </el-dropdown-item>
                                    <el-dropdown-item>
                                        <div class="line" @click="cancelMonitor(scope.row.company_name_digest, 1)">取消监测</div>
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>
                <page :config="pageConfig" @pageChange="pageChange" @limitChange="limitChange"></page>
            </div>
        </template>
        <div v-else style="width: 100%; height: 100%; background-color: #fff; overflow: hidden;">
            <NoData>
                <img slot="img" style="width: 187px; margin-bottom: 13px; margin-top: 141px" src="../../../assets/img/icon/no_jurisdiction.png"/>
                <div slot="text" style="text-align: center">
                    <div style="font-size: 16px; color: #333333; margin-bottom: 16px">暂无权限</div>
                    <div style="color: #999999; font-size: 14px;">标准版会员可查看竞品公司，请联系在线客服开通</div>
                </div>
            </NoData>
        </div>

<!--弹窗部分-->
        <el-dialog
            custom-class="my_dialog"
            :before-close="beforeClose"
            :visible.sync="showDialog">
            <template #title>
                <span class="my_dialog_title">切换公司</span>
            </template>
            <div class="search_line">
                <el-input
                    placeholder="请输入公司名称"
                    v-model="changedCompanyName"
                    @input="handleSearch()"
                    class="input-with-select">
                    <div slot="prepend">
                        <el-select v-model="searchType" placeholder="请选择" @change="changeSearchType">
                            <el-option label="全部公司" :value="1"></el-option>
                            <el-option label="我的收藏" :value="2"></el-option>
                        </el-select>
                    </div>
                    <div slot="append">
                        <el-button icon="el-icon-search" @click="handleSearch()"></el-button>
                    </div>
                </el-input>
            </div>
            <div class="collection_head" v-if="collectionList.length && !changedCompanyName">
                <img src="@/assets/img/search/icon_collect.png" alt="" width="14" height="14">
                <span>我的收藏</span>
            </div>
            <div class="collection_list" v-if="collectionList.length" v-loading="tableLoading2">
                <div class="list_item" v-for="(item,i) in collectionList" :key="i">
                    <span class="list_left">
                        <span class="company_name" @click="selectedCompany(item)">{{item.company_name}}</span>
                        <img @click="cancelCollect(item.company_name_digest)" v-if="item.collect" src="@/assets/img/search/collected.png" alt="" width="14" height="14">
                        <img @click="addCollect(item.company_name_digest)" v-else src="@/assets/img/search/uncollect.png" alt="" width="14" height="14">
                    </span>
                    <span class="list_right">
                        <el-checkbox v-model="item.default" @change="addDefault(item)" :disabled="item.default">设为默认</el-checkbox>
                    </span>
                </div>
            </div>
            <no-data v-else></no-data>
            <template #footer>
               <span class="dialog-footer">
                  <el-button @click="beforeClose">取 消</el-button>
                </span>
            </template>
        </el-dialog>

        <common_dialog
            @beforeClose="notVipShow = false"
            :visible.sync="notVipShow"
            :text="commonDialogText"
            width="40%">
        </common_dialog>

        <el-dialog
            custom-class="telephone_dialog"
            title="导出数据"
            :visible.sync="telDialog">
            <div class="line1">
                <span>数据量：</span>
                <span style="color: #1f81f8">{{exportCntTel}}</span>条
            </div>
            <div class="time">
                <span>今日还可以免费导出<span style="color: #ff8840;">{{surplusTel}}</span>次</span>
            </div>
            <span slot="footer" class="dialog-footer">
                <my-button @click="telDialog = false" type="secondary" width="80" height="33" style="margin-right: 11px">
                    <span slot="text">取消</span>
                </my-button>
                <my-button @click="handelExportTel" type="primary" width="80" height="33">
                    <span slot="text">确定</span>
                </my-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import * as api from "@/api/search"
import searchOption from "./searchOption";
import page from "../../../components/page";
import MyButton from "../../../components/myButton/myButton";
import PinganAna from 'pingansec-vue-ana'
import common_dialog from "../../../components/dialogs/common_dialog";

  export default {
    name: "findCompany",
    mixins: [searchOption],
    components: {
      MyButton,
      common_dialog,
    },
    data() {
      return {
        searchCompany: {},
        searchType: 1,
        listData:{},
        listSort_key: '',
        listSort_type: '',
        pageConfig: {
          page: 1,
          limit: 10,
          total: null,
          sizes: [10, 20, 30, 40, 50],
          count: 5,
          real_cnt: null
        },
        showDialog: false,
        changedCompanyName: '',  //切换公司搜索关键词
        collectionList: [], //我的收藏列表
        tableLoading: false,
        tableLoading2: false,
        areaVisible: false,
        search: {
          s_area: []
        },
        props: { multiple: true,  expandTrigger: 'hover'},
        area_name_str: "",
        area_name: "全部地区",
        notVipShow: false, //非vip导出提示弹窗
        commonDialogText: '', //弹窗提示内容
        surplusTel: '',
        exportCntTel: '',
        telDialog: false,
      }
    },
    created() {
      PinganAna.fire(3140)
    },
    mounted() {
      if(this.$store.state.userInfo.vip_type != 5) {
        if(this.$route.query.company) {
          this.selectedCompany(JSON.parse(this.$route.query.company));
        } else {
          this.getDefaultCompany();
        }
      }
    },
    methods: {
      exportTel() {
        api.get_last_export_cnt().then(res => {
          if(res.data.result_code==0) {
            this.surplusTel = res.data.data.total-res.data.data.used;
            this.exportCntTel = this.pageConfig.real_cnt > 1000?1000:this.pageConfig.real_cnt
            this.telDialog = true
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      },
      handelExportTel() {
        let params = {
          export_type: '11',
          export_limit: this.exportCntTel,
          export_condition: JSON.stringify({
            digest: this.searchCompany.company_name_digest,
            s_area: this.search.s_area,
            sort_key: this.listSort_key,
            sort_type: this.listSort_type,
            page: this.pageConfig.page,
            limit: this.pageConfig.limit,
          })
        }
        api.ge_export_task(params).then(res => {
          if(res.data.result_code==0) {
            this.$message({
              type: 'success',
              message: '导出任务创建成功！'
            })
            this.telDialog = false
            const { href } = this.$router.resolve({
              name: "export",
            });
            setTimeout(() => {
              window.open(href, '_blank');
            },500)
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      },
      handelMonitor(keyword,type) {
        let params = {
          keyword,
          type
        }
        api.add_monitor(params).then(res => {
          if(res.data.result_code==0) {
            this.$message({
              type: 'success',
              message: '监测成功！还可监测' +res.data.data.monitor_company_last +
                '家竞品公司，'+res.data.data.monitor_word_last+'个关键词'
            })
            this.init()
          } else {
            if(res.data.result_code == 120011 && (this.$store.state.userInfo.vip_type == 20 || this.$store.state.userInfo.vip_type == 10)) {
              this.commonDialogText = '您的监测数量已达上限，至尊版可监测50家竞品/100个关键词，如需监测更多，请联系您的客户经理开通'
              this.notVipShow = true;
            } else if(res.data.result_code == 120011 && this.$store.state.userInfo.vip_type == 5) {
              this.commonDialogText = '监测已达上限。标准版可监测竞品公司10家/关键词20个，请联系在线客服开通'
              this.notVipShow = true;
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          }
        })
      },
      cancelMonitor(keyword,type) {
        let params = {
          keyword,
          type
        }
        api.cancel_monitor(params).then(res => {
          if(res.data.result_code==0) {
            this.$message({
              type: 'success',
              message: '已取消监测！'
            })
            this.init()
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      },
      clearArea() {
        this.search.s_area = []
        this.area_name_str = ''
        this.area_name = '全部地区'
        this.init()
      },
      toCompare(scope, item) {
        item?PinganAna.fire(3145):''
        const { href } = this.$router.resolve({
          path: '/box/compare',
          query: {
            digest1: this.searchCompany.company_name_digest,
            digest2: scope.row.company_name_digest,
            word: item?item:''
          }
        });
        window.open(href, '_blank');
      },
      changeCompany() {
        this.showDialog = true;
        this.getCollectedData();
      },
      pageChange(val) {
        this.pageConfig.page = val
        this.init();
        document.getElementById("top").scrollIntoView()
      },
      limitChange(val) {
        this.pageConfig.page = 1;
        this.pageConfig.limit = val;
        this.init();
        document.getElementById("top").scrollIntoView()
      },
      listSortChange(column) {
        this.listSort_key = '2';
        if(column.order=='ascending') {
          this.listSort_type = '1'
        } else if (column.order=='descending') {
          this.listSort_type = '2'
        } else {
          this.listSort_type = ''
        }
        this.init()
      },
      getCompanyDetails(digest) {
        PinganAna.fire(3144)
        const { href } = this.$router.resolve({
          name: "companyDetails",
          query: {
            digest
          }
        });
        window.open(href, '_blank');
      },
      getCollectedData() {
        let params = {
          keyword: this.changedCompanyName,
          type: this.searchType
        };
        this.tableLoading2 = true;
        api.get_ad_compete_list_search(params).then(res => {
          if(res.data.result_code == 0) {
            if(res.data.data) {
              this.collectionList = res.data.data;
            }
            this.tableLoading2 = false;
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            });
            this.tableLoading2 = false;
          }
        })
      },
      addCollect(digest) {
        let params = {
          digest
        };
        api.add_collect(params).then(res =>{
          if(res.data.result_code == 0) {
            this.$message({
              type: 'success',
              message: '收藏成功，还可收藏' + res.data.data.last + '家公司'
            })
            this.getCollectedData()
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      },
      cancelCollect(digest) {
        let params = {
          digest
        };
        api.cancel_collect(params).then(res => {
          if(res.data.result_code == 0) {
            this.$message({
              type: 'success',
              message: '取消收藏成功，还可收藏' + res.data.data.last + '家公司'
            })
            this.getCollectedData()
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      },
      addDefault(item) {
        let params = {
          key: 'search_1',
          val: item.company_name_digest
        };
        api.set_val_by_key(params).then(res => {
          if(res.data.result_code == 0) {
            this.$message({
              type: 'success',
              message: '设置默认成功！'
            });
            this.getCollectedData()
          } else {
            this.$message({
              type: 'error',
              message: '设置默认失败！'
            })
          }
        })
      },
      beforeClose() {
        this.showDialog = false;
        this.changedCompanyName = '';
        this.clearArea()
      },
      handleSearch() {
        let params = {
          keyword: this.changedCompanyName,
          type: this.searchType
        };
        this.tableLoading2 = true;
        api.get_ad_compete_list_search(params).then(res => {
          if(res.data.result_code == 0) {
            this.collectionList = res.data.data;
            this.tableLoading2 = false;
          }
        })
      },
      changeSearchType() {
        this.changedCompanyName = '';
      },
      selectedCompany(item) {
        this.searchCompany = item;
        this.beforeClose();
      },
      init() {
        let params = {
          digest: this.searchCompany.company_name_digest,
          s_area: this.search.s_area,
          sort_key: this.listSort_key,
          sort_type: this.listSort_type,
          page: this.pageConfig.page,
          limit: this.pageConfig.limit,
        };
        this.tableLoading = true;
        api.get_ad_compete_list_v2(params).then(res => {
            if(res.data.result_code == 0) {
              PinganAna.fire(3143)
              if(res.data.data) {
                this.listData = res.data.data;
                this.listData.rows.map(item => {
                  item.showDialog = false;
                  return item;
                })
                this.pageConfig.total = parseInt(res.data.data.cnt)
                this.pageConfig.real_cnt = parseInt(res.data.data.real_cnt)
                PinganAna.ready(() => {
                  PinganAna.userClickLog(
                    '"Position":"搜索"',
                    '{"searchType":"智能匹配竞品","pageName":"'+this.$route.name+'",'+JSON.stringify(params)+'}',
                    '【AI竞投】系统',
                    this.$store.state.userInfo.vip_type == 5?3:this.$store.state.userInfo.vip_type == 10?1:2,
                    '--',
                    this.$store.state.userInfo.account
                  )
                })
              }
              this.tableLoading = false
            }
        })
      },
      getDefaultCompany() {
        let params = {
          key: 'search_1',
        };
        api.get_val_by_key(params).then(res => {
          if(res.data.result_code == 0) {
            this.searchCompany = res.data.data;
            this.searchCompany.default = true;
            this.init();
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
.find_competitive {
    height: 100%;
    .click {
        cursor: pointer;
    }
    .new_link {
        color: #1f81f8;
       &:hover {
           cursor: pointer;
           text-decoration: underline #1f81f8;
       }
    }
    .search_company {
        width: 100%;
        background-color: #ffffff;
        border-radius: 5px;
        color: #999999;
        font-size: 16px;
        .header_title {
            color: #000000;
            font-size: 16px;
            padding: 19px;
            border-bottom: 1px solid #ebeff2;
        }
        .company {
            display: flex;
            align-items: center;
            padding: 29px 20px 26px 20px;
            border-bottom: 1px solid #ebeff2;
        }
        .right {
            display: flex;
            align-items: center;
        }
        .text {
            color: #333333;
            margin-left: 5px;
            margin-right: 16px;
        }
        .area {
            padding: 20px;
            .s-l-label {
                font-size: 14px;
                color: #999999;
            }
            .s-l-box {
                display: inline-flex;
                font-size: 14px;
                color: #333333;
                cursor: pointer;
                .checkbox {
                    width: 122px;
                    display: flex;
                    align-items: center;
                    i {
                        width: 14px;
                        height: 14px;
                        background: url('../../../assets/img/search/checkbox.png') center center no-repeat;
                        background-size: 14px;
                        margin-right: 6px;
                    }
                    .myself-hover {
                        display: flex;
                        align-items: center;
                    }
                }
                .checkboxed {
                    i {
                        background: url('../../../assets/img/search/checkboxed.png') center center no-repeat;
                        background-size: 14px;
                    }
                }
            }
        }
        .select-list {
            width: 100%;
            display: flex;
            padding: 2px 0 12px 20px;
            box-sizing: border-box;
            .select-list-label {
                @extend .font-normal;
                font-size: 14px;
                color: #999999;
                line-height: 26px;
            }

            .select-list-box {
                margin-left: 10px;
                flex: 1;
                display: flex;
                flex-wrap: wrap;

                span {
                    background: rgba(31, 129, 248, 0.1);
                    padding: 6px 10px;
                    @extend .font-normal;
                    font-size: 14px;
                    color: #1f81f8;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    border-radius: 3px;
                    display: flex;
                    align-items: center;

                    font {
                        display: inline-block;
                        width: 8px;
                        height: 7px;
                        background: url('../../../assets/img/search/close.png') center center no-repeat;
                        background-size: 8px 7px;
                        margin-left: 8px;
                        cursor: pointer;
                    }
                }
            }

            .select-list-clear {
                @extend .font-normal;
                font-size: 14px;
                color: #999999;
                margin-right: 18px;
                background: url('../../../assets/img/search/delete.png') left 6px no-repeat;
                background-size: 14px;
                text-indent: 20px;
                line-height: 26px;
                width: 80px;
                cursor: pointer;
            }
        }
        .cascader-span {
            color: $c1f81f8;
        }
    }
    .list_box {
        background-color: #ffffff;
        border-radius: 5px;
        border: solid 1px #ebeff2;
        margin-top: 15px;
        .head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 14px;
            border-bottom: 1px solid #ebeff2;
            font-size: 14px;
            color: #999999;
            padding: 20px;
            .count {
                color: #ef3819;
            }
        }
        .list_item {
            width: 100%;
            display: inline-flex;
            .list-left {
                display: inline-block;
                .list-left-txt {
                    @extend .font-normal;
                    width: 50px;
                    height: 50px;
                    background-color: #3aa678;
                    opacity: .6;
                    border-radius: 3px;
                    font-size: 14px;
                    line-height: 16px;
                    color: #fff;
                    padding: 10px 11px;
                    box-sizing: border-box;
                    margin-left: 25px;
                    margin-top: 14px;
                    margin-right: 25px;
                    margin-bottom: 14px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    font {
                        text-align: center;
                    }
                }
            }
            .list_right {
                position: relative;
                width: 100%;
                .company_line {
                    display: inline-flex;
                    align-items: center;
                    margin-top: 14px;
                    .company_name {
                        cursor: pointer;
                        font-size: 18px;
                        color: #333333;
                        max-width: 400px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        &:hover {
                            color: #1f81f8;
                        }
                    }
                    .running {
                        display: inline-block;
                        font-size: 12px;
                        font-weight: normal;
                        line-height: 12px;
                        color: #1f81f8;
                        padding: 3px 6px 4px 6px;
                        margin-left: 12px;
                        border-radius: 5px;
                        background-color:  rgb(31,129,248,0.1);
                    }
                    .close_down {
                        display: inline-block;
                        font-size: 12px;
                        line-height: 12px;
                        font-weight: normal;
                        color: #ef3819;
                        padding: 3px 6px 4px 6px;
                        margin-left: 12px;
                        border-radius: 5px;
                        background-color:  rgb(239,56,25,0.1);
                    }
                }
                .extend {
                    font-size: 12px;
                    margin-top: 12px;
                    .extend_item {
                        display: inline-flex;
                        align-items: center;
                        flex-wrap: wrap;
                    }
                    img  {
                        width: 14px;
                        height: 14px;
                        margin-right: 5px;
                    }
                }
                .title {
                    color: #999999;
                }
                .container {
                    color: #666666;
                    margin-right: 12px;
                }
            }
        }
    }
}

</style>

<style lang="scss">
.find_competitive .el-table th.is-leaf {
    background-color: #f8fafc;
    color: #666666;
}
.find_competitive .el-table td, .el-table th {
    padding: 24px 0;
}
.find_competitive .telephone_dialog {
    width: 435px;
    height: 188px;
    background-color: #ffffff;
    border-radius: 5px;
    border: solid 1px #ebeff2;
    .el-dialog__header {
        padding: 20px;
        font-size: 16px;
        color: #333333;
    }
    .el-dialog__body {
        padding: 27px 20px;
        padding-top: 12px;
        font-size: 14px;
        .line1 {
            color: #333;
            margin-bottom: 10px;
        }
        .time {
            color: #999;
        }
    }
    .el-dialog__footer {
        padding-top: 0;
    }
}
.find_competitive .my_dialog {
    width:  435px;
    background-color: #ffffff;
    border-radius: 5px;
    border: solid 1px #ebeff2;
    .el-dialog__header {
        border-bottom: 1px solid #ebeff2;
        color: #333333;
        font-size: 16px;
        padding: 20px;
    }
    .el-dialog__body {
        .search_line {
           .el-input-group__prepend {
               width: 93px;
               .el-select {
                   margin: -10px -5px;
               }
           }
        }
        .collection_head {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #b5c2d2;
            height: 44px;
            border-bottom: 1px solid #ebeff2;
            box-sizing: border-box;
            img {
                margin-right: 7px;
            }
        }
        .collection_list {
            font-size: 14px;
            color: #333333;
            max-height: 200px;
            overflow-y: auto;
            scrollbar-width: none; /* Firefox */
            &::-webkit-scrollbar {
                display: none; /* Chrome Safari */
            }
            .list_item {
                display: flex;
                justify-content: space-between;
                margin-top: 20px;
                .list_left {
                    display: inline-flex;
                    align-items: center;
                    span {
                        cursor: pointer;
                    }
                    .company_name {
                        &:hover {
                            color: #1f81f8;
                        }
                    }
                }
                img {
                    margin-left: 8px;
                    cursor: pointer;
                }
                .list_right {
                    .el-checkbox__inner {
                        border-radius: 60%;
                    }
                    .el-checkbox__input.is-disabled+span.el-checkbox__label {
                        color: #2a87f8;
                    }
                    .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
                        background-color: #409EFF;
                        border-color: #409EFF;
                    }
                }
            }
        }
    }
    .el-dialog__footer {
        padding: 12px 15px;
        border-top: 1px solid #ebeff2;
    }
}
</style>
